import React, { useContext } from "react";
import { Table, Button, Container, Row, Col } from "reactstrap";
import { useRouter } from "next/compat/router";
import styled from "styled-components";
import { AuthContext } from "helpers/auth/AuthContext";
import vars from "helpers/utils/vars";
import { isEmpty } from "helpers/utils/helpers";

export const getColorStyles = (membership = "") => {
  const _membership = membership.toLowerCase();
  switch (_membership) {
    case "diamond":
      return { backgroundColor: "#b9f2ff", color: "#00bcd4" };
    case "platinum":
      return { backgroundColor: "#E5E4E2", color: "#607d8b" };

    default:
      return { backgroundColor: _membership, color: "#fff" };
  }
};

const DashboardPlan = ({ membershipTypes }) => {
  const router = useRouter();
  const authContext = useContext(AuthContext);
  const user = authContext.user;

  const goToMyPlan = () => {
    if (user.role === "seller") {
      router.push({
        pathname: "/seller/account",
        query: { active: "plan" },
      });
    } else if (user.role === "buyer") {
      router.push({
        pathname: "/buyer/account",
        query: { active: "plan" },
      });
    }
  };

  const { subs } = user;

  if (isEmpty(subs)) {
    return (
      <h4
        className="f-w-600 mb-4 dashboard-title text-center"
        style={{ color: "red" }}
      >
        Malformed data found. No user.subs info available.
      </h4>
    );
  }
  const headerStyles={backgroundColor: vars.blackColor, color:'#ffffff', padding: '12px'};

  return (
    <Container className="attribute-blocks mb-5">
      <h4 className="f-w-600 mb-4 dashboard-title text-center">
        Current Subscription Plan
      </h4>

      <Row className="justify-content-center">
        <Col xs="12" md="8">
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: 5,
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            }}
          >
            <Table
              responsive
              style={{
                marginBottom: 0,
                width: "100%",
              }}
            >
              <thead>
                <Tr style={{ backgroundColor: vars.blackColor }}>
                  <Th style={headerStyles}>Plan</Th>
                  <Th style={headerStyles}>Type</Th>
                  <Th style={headerStyles}>Price</Th>
                  <Th style={headerStyles}>Renewal Date</Th>
                </Tr>
              </thead>
              <tbody>
                <tr>
                  <Td
                    style={{
                      textAlign: "left",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        padding: "8px 15px",
                        borderRadius: 25,
                        maxWidth: "fit-content",
                        ...getColorStyles(subs.membership_type),
                      }}
                    >
                      {subs.membership_type}
                    </div>
                  </Td>
                  <Td>{subs.cycle_period === "month" ? "Month" : "Annual"}</Td>
                  <Td>{parseFloat(subs.amount).toFixed(2)}</Td>
                  <Td>{subs.to_date}</Td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>

        <Col
          xs="12"
          className="d-flex justify-content-center align-items-center mt-3"
        >
          <div className="d-flex">
            <button
              className="btn btn-post btn-solid btn-default-plan me-2"
              onClick={goToMyPlan}
            >
              Upgrade
            </button>
            <Button onClick={goToMyPlan}>View all plans</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardPlan;

export const Tr = styled.tr`
  background-color: ${vars.primaryColor};
  text-align: center; // Center align text in the table header
`;
export const Th = styled.th`
  border-top: none !important;
  border-bottom: none !important;
  color: #fff;
  text-align: center; // Center align text in the table header
`;
export const Td = styled.td`
  vertical-align: middle !important;
  font-weight: 500;
  text-align: center; // Center align text in the table cells by default
`;
